*{
  padding: 0;
  margin: 0;
}

#App{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a, h1, h2, h3, span, button{
  font-family: 'Roboto', sans-serif;
}

a, button{
  text-decoration: none;
  color: black;
  font-weight: bold;
}

#Home{
  display: grid;
  gap: 100px;
}


#Header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  border-bottom: 2px solid red;
}

#Header img{
  width: 150px;
}

#Menu{
  display: flex;
  gap: 10px;
  text-transform: uppercase;
}

#Hero{
  display: grid;
  color: white;
  height: 30vw;
}

#Hero img{
  width: stretch;
  position: absolute;
  z-index: -1;
}

#HeroText{
  position: relative;
  top: 13vw;
  left: 10%;
  display: grid;
  width: 30%;
  gap: 10px;
  height: fit-content;
}

@media only screen and (max-width: 1440px){
  #HeroText{
    position: relative;
    top: 9vw;
    left: 10%;
    display: grid;
    width: 30%;
    gap: 10px;
    height: fit-content;
  }
}

#SubHero{
  display: flex;
  width: 90%;
  justify-content: space-evenly;
  justify-self: center;
}

#SubHeroCell{
  display: grid;
  gap: 10px;
  width: 20vw;
}

#SubHeroCell img{
  max-width: 400px;
  width: 20vw;
}

#BuySection{
  display: grid;
  gap:20px;
  background: black;
  color: white;
  text-align: center;
  padding: 50px 0px;
}

#eCommerce{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 50px;
}

#eCommerce img{
  width: 150px;
  height: auto;
}

#FinalHero{
  margin-top: -100px;
  margin-bottom: -8px;
  height: 50vw;
  max-height: 950px;
  display: grid;
  color: white;
  justify-items: center;
  text-align: center;
}

#FinalHeroText{
  display: grid;
  margin-top: 16vw;
  gap: 20px;
  width: 33vw;
  height: fit-content;
}

#FinalHero img{
  width: 100%;
  position: absolute;
  z-index: -1;
}

#Products{
  display: grid;
  gap: 10px;
  text-align: center;
  padding: 30px;
}

#ProductsSection{
  margin-top: 40px;
  display: flex;
  justify-content: space-evenly;
}

#ProductCell{
  display: grid;
  gap: 10px;
  justify-items: center;
}

#ProductCell img{
  width: 400px;
}

#ProductCell button{
  background: red;
  color: white;
  border: none;
  border-radius: 5px;
  transition: 0.2s linear;
  outline-offset: -2px;
}

#ProductCell button:hover{
  cursor: pointer;
  background: none;
  outline: 2px solid red;
  color: red;
}

#Spectre{
  display: grid;
}

#ProductHero{
  display: grid;
  grid-auto-flow: column;
  align-items: start;
  gap: 30px;
  padding: 30px;
  width: 70%;
  justify-self: center;
}

#ProductHeroLeft img{
  width: 40vw;
}

#ProductHeroRight{
  display: grid;
  gap: 10px;
}

#ProductSpecs{
  display: grid;
  background: #dadada;
  padding: 10px;
  border-radius: 10px;
}

#ProductSpecsExpand, #ProductCompExpand, #ProductBoxExpand{
  display: grid;
  height: 0;
  overflow: hidden;
  transition: height 200ms linear;
}

#ProductSpecsExpand h3, #ProductCompExpand h3, #ProductBoxExpand h3{
  text-transform: uppercase;
}

#ProductSubhero{
  display: grid;
  gap: 20px;
  background: radial-gradient(darkslategray, black);
  padding: 5vw 10vw;
  color: white;
  justify-items: center;
  text-align: center;
}

#ProductSubheroTrio{
  display: flex;
  gap: 30px;
  padding: 30px;
  border-top: 2px solid gray;
}

#ProductSubheroCell{
  display: grid;
  gap: 20px;
}

#ProductSubheroCell h2{
  color: mediumaquamarine;
}

#ProductSubhero2{
  display: grid;
  height: 50vw;
  max-height: 950px;
}

#ProductSubhero3{
  display: flex;
  padding: 50px;
  justify-items: center;
  gap: 50px;
  width: 70%;
  justify-self: center;
  align-items: center;
}

#ProductSubhero3 img{
  width: 33vw;
}

#ProductSubhero3Subtext{
  display: grid;
  gap: 30px;
  height: fit-content;
}

#ProductSubhero2 img{
  position: absolute;
  width: stretch;
  z-index: -1;
}

#SpectreSubheroText{
  position: relative;
  top: 16vw;
  left: 5vw;
  display: grid;
  height: fit-content;
  width: 30vw;
  gap: 20px;
}

#SpectreSubheroBorder{
  width: 60px;
  height: 10px;
  background: black;
}

#About{
  display: grid;
}

#AboutHero{
  display: grid;
  text-align: center;
  color: white;
}

#AboutHero img{
  position: absolute;
  width: stretch;
  z-index: -1;
}

#AboutSubhero{
  display: grid;
  gap: 20px;
  background: radial-gradient(darkslategray, black);
  padding: 100px 300px;
  color: white;
  justify-items: center;
  text-align: center;
}

#AboutSubhero2{
  display: grid;
  padding: 100px 300px;
  justify-items: center;
  gap: 30px;
  text-align: center;
}

#Footer{
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  padding: 30px;
  align-items: center;
  border-top: 2px solid red;
}

#FooterLeft{
  display: grid;
  gap: 5px;
}

#FooterMid{
  display: flex;
  align-items: center;
  font-weight: bold;
}

#FooterMid img{
  width: 100px;
}

#FooterRight{
  display: flex;
  gap: 10px;
  align-content: center;
}

button{
  width: fit-content;
  padding: 20px 30px;
  background: white;
  border: none;
  border-radius: 5px;
  transition: 0.2s linear;
  outline-offset: -2px;
}

button:hover{
  cursor: pointer;
  background: none;
  outline: 2px solid white;
  color: white;
}

@media only screen and (max-width: 480px){
#Header{
  display: grid;
  justify-content: center;
  justify-items: center;
  gap: 20px;
}
#Hero{
  display: grid;
  color: white;
  height: 300px;
  background: radial-gradient(blue,black);
  width: 100vw;
}
#Hero img{
  display: none;
  width: stretch;
  position: absolute;
  z-index: -1;
}
#HeroText{
  position: relative;
  top: 13vw;
  left: 10%;
  display: grid;
  width: 80%;
  gap: 10px;
}
#SubHero{
  display: grid;
  width: calc(100vw - 60px);
  justify-content: center;
  justify-self: center;
  gap: 30px;
}
#SubHeroCell{
  display: grid;
  gap: 10px;
  width: 100%;
}
#SubHeroCell img{
  max-width: 400px;
  width: 20vw;
}
#BuySection{
  display: grid;
  gap: 20px;
  background: black;
  color: white;
  text-align: center;
  padding: 50px;
  width: calc(100vw - 100px);
}
#eCommerce{
  display: grid;
  justify-content: center;
  gap: 20px;
  align-items: center;
  padding: 50px;
}
#eCommerce img{
  width: 150px;
  height: auto;
}
#FinalHero{
  margin-top: -100px;
  margin-bottom: 0px;
  height: fit-content;
  max-height: 950px;
  display: grid;
  color: white;
  justify-items: center;
  text-align: center;
  background: radial-gradient(red,black);
  padding: 30px;
  width: calc(100vw - 60px);
}
#FinalHeroText{
  display: grid;
  margin-top: 0;
  gap: 20px;
  width: 100%;
  height: fit-content;
}
#FinalHero img{
  display: none;
  width: 100%;
  position: absolute;
  z-index: -1;
}
#About{
  display: grid;
}
#AboutHero{
  display: grid;
  text-align: center;
  color: white;
  background: radial-gradient(red,black);
}
#AboutHero img{
  display: none;
  position: absolute;
  width: stretch;
  z-index: -1;
}
#AboutSubhero{
  display: grid;
  gap: 20px;
  background: radial-gradient(darkslategray, black);
  padding:30px;
  color: white;
  justify-items: center;
  text-align: center;
}
#AboutSubhero2{
  display: grid;
  padding: 30px;
  justify-items: center;
  gap: 30px;
  text-align: center;
}
#Spectre{
  display: grid;
}
#ProductCell img{
  width: 50vw;
}

#ProductHero{
  display: grid;
  grid-auto-flow: row;
  align-content: center;
  justify-items: center;
  gap: 30px;
  padding: 30px 0;
  width: 90%;
  justify-self: center;
}

#ProductHeroLeft img{
  justify-self: center;
  width: 80vw;
}

#ProductHeroRight{
  display: grid;
  gap: 10px;
}

#ProductSpecs{
  display: grid;
  background: #dadada;
  padding: 10px;
  border-radius: 10px;
}

#ProductSpecsExpand, #ProductCompExpand, #ProductBoxExpand{
  display: grid;
  height: 0;
  overflow: hidden;
  transition: height 200ms linear;
}

#ProductSpecsExpand h3, #ProductCompExpand h3, #ProductBoxExpand h3{
  text-transform: uppercase;
}

#ProductSubhero{
  display: grid;
  gap: 20px;
  background: radial-gradient(darkslategray, black);
  padding: 30px;
  color: white;
  justify-items: center;
  text-align: center;
}

#ProductSubheroTrio{
  display: grid;
  gap: 30px;
  padding: 30px;
  border-top: 2px solid gray;
}

#ProductSubheroCell{
  display: grid;
  gap: 20px;
}

#ProductSubheroCell h2{
  color: mediumaquamarine;
}

#ProductSubhero2{
  display: grid;
  height: 400px;
  max-height: 100vh;
  background: radial-gradient(pink,lavender);
}

#ProductSubhero2 img{
  display: none;
  position: absolute;
  width: stretch;
  z-index: -1;
}

#ProductSubhero3{
  display: grid;
  padding: 30px;
  justify-items: center;
  gap: 50px;
  width: 70%;
  justify-self: center;
  align-items: center;
}

#ProductSubhero3 img{
  width: 70vw;
}

#ProductSubhero3Subtext{
  display: grid;
  gap: 30px;
  height: fit-content;
}

#SpectreSubheroText{
  position: relative;
  top: 16vw;
  left: 5vw;
  display: grid;
  height: fit-content;
  width: 80%;
  gap: 20px;
}

#SpectreSubheroBorder{
  width: 60px;
  height: 10px;
  background: black;
}
#Footer{
  margin-top: auto;
  display: grid;
  justify-content: center;
  justify-items: center;
  padding: 30px;
  align-items: center;
  border-top: 2px solid red;
  gap: 20px;
}
#FooterLeft{
  display: grid;
  gap: 5px;
}
#FooterMid{
  display: flex;
  align-items: center;
  font-weight: bold;
}
#FooterMid img{
  width: 100px;
}
#FooterRight{
  display: flex;
  gap: 10px;
  align-content: center;
}
}